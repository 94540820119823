export default class Nav{
    constructor(){
        // document.addEventListener("DOMContentLoaded", function() {
        //     const buttons = document.querySelectorAll(".btn");
        //     const slidingBar = document.getElementById("sliding-bar");
        //     const buttonContainer = document.querySelector(".button-container");
        
        //     // Initial position of the sliding bar (Button 1)
        //     slidingBar.style.width = `${buttons[0].offsetWidth}px`;
        //     slidingBar.style.transform = `translateX(${buttons[0].offsetLeft - buttonContainer.offsetLeft}px)`;
        
        //     buttons.forEach((button, index) => {
        //         button.addEventListener("mouseenter", () => {
        //             slidingBar.style.width = `${button.offsetWidth}px`;
        //             slidingBar.style.transform = `translateX(${button.offsetLeft - buttonContainer.offsetLeft}px)`;
        //         });
        //     });
        
        //     // Optional: Reset the sliding bar position to the first button when not hovering any button
        //     buttonContainer.addEventListener("mouseleave", () => {
        //         slidingBar.style.width = `${buttons[0].offsetWidth}px`;
        //         slidingBar.style.transform = `translateX(${buttons[0].offsetLeft - buttonContainer.offsetLeft}px)`;
        //     });
        // });
    }
}